import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

import firebase from 'firebase/compat/app';
import {getAuth} from 'firebase/auth';

const config = {
  apiKey: "AIzaSyDs-03tHRm0CAwm9WktjCXfxVijPKEtv6A",
  authDomain: "mywazovski.firebaseapp.com",
  projectId: "mywazovski",
  storageBucket: "mywazovski.appspot.com",
  messagingSenderId: "989594695384",
  appId: "1:989594695384:web:e78c332e1d9584fb6d89ad",
  measurementId: "G-06VQN4T0TG"
};

let app;

const initializeFirebase = () => {
  if (!firebase.apps.length) {
    app = firebase.initializeApp(config);
  }
};

initializeFirebase();

const auth = getAuth(app);
const firebaseAuth = firebase.auth(app);
const storage = firebase.storage(app);
const firestore = firebase.firestore(app);
const functions = firebase.functions(app);

export {app, firestore, storage, auth, firebaseAuth, functions};
