import Login from '../pages/auth/Login';
import Users from '../pages/users/Index';
import UserDetail from '../pages/users/User';
import DepositAcc from '../pages/users/DepositAcc';
import MelbetDeposits from '../pages/melbet/Index';
import XbetDeposits from '../pages/1xbet/Index';
import KhanStatements from '../pages/khan/Index';

import {Routes} from './route_helper';
import NotFound from '../pages/NotFound';
import Withdraws from '../pages/withdraw/Index';
import Otp from '../pages/otp/Index';
import MHistories from '../pages/melbet/History';
import XHistories from '../pages/1xbet/History';
import CallUsers from '../pages/callusers/Index';

const authProtectedRoutes = [
  {path: Routes.otp, component: Otp},
  {path: Routes.callusers, component: CallUsers},
  {path: Routes.users, component: Users},
  {path: Routes.user, component: UserDetail},
  {path: Routes.withdraws, component: Withdraws},
  {path: Routes.depositAcc, component: DepositAcc},
  {path: Routes.melbethistory, component: MHistories},
  {path: Routes.xbethistory, component: XHistories},
  {path: Routes.auto, component: MelbetDeposits},
  {path: Routes.xbet, component: XbetDeposits},
  {path: Routes.khanStatements, component: KhanStatements},

];

const initialRoute = [{path: '*', component: NotFound}];
const publicRoutes = [{path: Routes.login, component: Login}];


export {
  publicRoutes,
  initialRoute,
  authProtectedRoutes,
};
