import { useState, useEffect } from 'react';
import * as _ from 'lodash';
import DataTable from '../../components/table/Index';
import type { ColumnsType } from 'antd/es/table';
import { DepositModel } from '../../types';
import { Timestamp } from '@firebase/firestore';
import { convertDateTimeFromTimestamp } from '../../helpers/utils';
import DepositDetailModal from '../depo/Detail';
import { Button, Spin, Col, Row, message, Tag, Input, DatePicker, } from 'antd';

import dayjs from 'dayjs';
import type { DatePickerProps } from 'antd';
import { useDoc, useCollection } from '../../helpers/firestore';
import { firestore } from '../../firebase';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import TableHeader from '../../components/table/TableHeader';
import { checkMobBalance } from '../../helpers/firebase_functions';

dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';
const MelbetDeposits = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [user, setUser] = useState('');
  const [date, setDate] = useState(dayjs(new Date()));
  const [selDate, setSelDate] = useState(dayjs(new Date()).format(dateFormat));
  const [data, setData] = useState([]);
  const [teamCashBalance, teamCashBalanceLoading] = useDoc('configs/teamCashBalance');
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingBal, setLoadingBal] = useState<boolean>(false);
  const [selected, setSelected] = useState({} as DepositModel);
  const [open, setOpen] = useState(false);

  const getDepositsData = async () => {
    setLoading(true);

    const startOfDay = dayjs(selDate).startOf('day').toDate(); // Start of today
    const endOfDay = dayjs(selDate).endOf('day').toDate(); // End of today
    const startOfDayTimestamp = Timestamp.fromDate(startOfDay);
    const endOfDayTimestamp = Timestamp.fromDate(endOfDay);

    let ref: any = firestore.collection('deposits').where('betType',"==","melbet");
    if (user)
      ref = ref.where('betID', '==', user)
    const depoData = await ref.where("createdAt", ">=", startOfDayTimestamp).
      where("createdAt", "<=", endOfDayTimestamp).orderBy('createdAt', 'desc').get();
    // Execute the query
    setData(depoData.docs.map((d: any) => ({ ...d.data(), uid: d.id })));

    setLoading(false);
  };

  useEffect(() => {
    getDepositsData();
  }, []);


  const toggleModal = () => setOpen(!open);
  const onChange: DatePickerProps['onChange'] = (dateJs, dateString) => {
    setSelDate(dateString as string)
  };
  function numberWithCommas(x: string) {
    if(!x)
      return
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ₮" ;
  }
  const columns: ColumnsType<DepositModel> = [

    {
      key: 'betID',
      title: 'AccountID',
      dataIndex: 'betID',
      width: 125,
    },

    {
      key: 'amount',
      title: 'Цэнэглэлт дүн',
      dataIndex: 'amount',
      render: (amount: any) => {return numberWithCommas(amount.amount)}
    },
    {
      key: 'bonus',
      title: 'Bonus',
      dataIndex: 'bonus',
      render: (bonus: any) => {return numberWithCommas(bonus)}
    },
    {
      key: 'statusMsg',
      title: 'Төлөв',
      dataIndex: 'statusMsg',
      render: (statusMsg: string,record) => {
        if(record.status =='new'){
          return <Tag  color="processing"> {statusMsg}</Tag>
        }
        if(record.status =='review'){
          return <Tag  color="warning"> {statusMsg}</Tag>
        }
        if(record.status =='Done'){
          return <Tag  color="success"> {statusMsg}</Tag>
        }
        if(record.status =='MelError'){
          return <Tag color="error"> {statusMsg}</Tag>
        }
        

        
      }
    },

    {
      key: 'transactionDate',
      title: 'Гүйлгээ огноо',
      dataIndex: 'transactionDate',
      width: 125,
      render: (transactionDate: string) => transactionDate.slice(0,10),
    },
    {
      key: 'txnTime',
      title: 'цаг',
      dataIndex: 'txnTime',
    },


    {
      key: 'lvl',
      title: 'LVL',
      dataIndex: 'lvl',
      render: (lvl: Number) => {return (<>{`+ ${lvl}`}</>)}
    },
  ];



  const onClickRow = (d: any) => {
    console.log(d)
    setSelected(d);
    toggleModal();
  };
  const reloadData = async (type:string) => {
    if (!loadingBal) {
      setLoadingBal(true)
      const data = await checkMobBalance(type)
      setLoadingBal(false)
      messageApi.open({
        type: 'success',
        content: 'Мэдээлэл шинэчлэдлээ',
      });
    } else {
      messageApi.open({
        type: 'info',
        content: 'Мэдээлэл шинэчлэж байна',
      });
    }

    console.log(data)
  };
  return (
    <div>
      {contextHolder}
      <Row gutter={[16, 16]}>
        <Col xs={22} lg={12}>
          <TableHeader
            tableName='Melbet данс'
            reloadData={()=>reloadData('melbet')}
          />
          <div className="card-container px-5 py-7">

            <Spin spinning={teamCashBalanceLoading}>
              {teamCashBalance && (
                <>
                  <div className=" text-lg text-gray">Close</div>
                  <h2>{teamCashBalance.Close ? ('Хаагдсан') : ("Нээлттэй")}</h2>

                  <div className=" text-lg text-gray">Үлдэгдэл</div>
                  <h2>{numberWithCommas(teamCashBalance.ImperiumBalance)}</h2>
                  <div className=" text-lg text-gray">Oгноо</div>
                  <h2>{convertDateTimeFromTimestamp(teamCashBalance.date)}</h2>
                </>
              )}

            </Spin>
          </div>
        </Col>
   
      </Row>
      <DataTable
        tableName="Deposits"
        columns={columns}
        data={data || []}
        loading={loading}
        reloadData={getDepositsData}
        onSearch={getDepositsData}
        onClickRow={onClickRow}
        filter={[
          <Input
            key="melbetid"
            size="small"
            className="w-auto"
            placeholder="MelbetID"
            value={user}
            onChange={(e) => setUser(e.target.value)}
          />,
          <DatePicker defaultValue={date} onChange={onChange} />,
        ]}
      />
      <DepositDetailModal data={selected} open={open} toggle={toggleModal} goUser/>
    </div>
  );
};

export default MelbetDeposits;
