import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, message, Form, InputNumber } from 'antd';
import * as _ from 'lodash';
import { convertDateTimeFromTimestamp } from '../../helpers/utils';

import { DepositAccConfig } from '../../types';
import { saveData } from '../../helpers/firestore';

type Props = {
  open?: boolean;
  toggle: (value: boolean) => void;
  data: DepositAccConfig;
};

const DepositConfigEdit: React.FC<Props> = ({ data, open = false, toggle }) => {
  const [form] = Form.useForm();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  // useEffect(() => {
  //   if (data != null) {
  //     setLimit(data.limit);
  //     setLVL(data.lvl);
  //     setMaxDepoPerDay(data.max_depo_per_day);
  //   }
  // }, [data]);



  const onSave = async (values: DepositAccConfig) => {
    setLoadingSave(true);
    await saveData('wazsConfig', data.uid, values)
    messageApi.open({
      type: 'success',
      content: "Амжилттай хадгаллаа",
    });
    toggle(false)


    setLoadingSave(false);

  };
  const onCancel = () => {
    setError('');
    setLoading(false);
    setLoadingSave(false);
    toggle(false);
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title=""
        onCancel={onCancel}
        className="info-modal"
        centered
        footer={[]}
      >
        <div className="dialog-body">
          <div className="px-8">
            <div className="font-medium text-lg text-center my-5">
              Дансны мэдээлэл
            </div>
            <Form
              form={form}
              onFinish={onSave}
              initialValues={data}
              scrollToFirstError
            >

              <div className="font-medium">Гүйлгээний лимит</div>
              <Form.Item
                rules={[{
                  required: true,
                  type: 'number',
                  min: 1000,
                  max: 20000000,
                  message: 'Гүйлгээний лимит оруулна уу! min 1,000 max 20,000,000 '
                }]}
                name="limit"
              >
                <InputNumber

                  style={{ width: '70%' }}
                />
              </Form.Item>
              <div className="font-medium">Өдрийн гүйлгээний тоо</div>
              <Form.Item
                rules={[{
                  required: true,
                  type: 'number',
                  min: 1,
                  max: 1000,
                  message: 'Өдрийн гүйлгээний тоо оруулна уу! min 1 max 1000 '
                }]}
                name="max_depo_per_day"
              >
                <InputNumber />
              </Form.Item>

              <div className="font-medium">LVL</div>
              <Form.Item
                rules={[{
                  required: true,
                  message: 'LVL оруулна уу! min 1 max 256',
                  type: 'number',
                  min: 1,
                  max: 256,
                }]}
                name="lvl"
              >
                <InputNumber />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Хадгалах
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>

    </>
  );
};

export default DepositConfigEdit;
