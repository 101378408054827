

import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, message, Form, InputNumber } from 'antd';
import * as _ from 'lodash';
import { convertDateTimeFromTimestamp } from '../../helpers/utils';

import { DepositAccConfig } from '../../types';
import { saveData } from '../../helpers/firestore';
import { manualTransaction } from '../../helpers/firebase_functions';

type Props = {
  open?: boolean;
  toggle: (value: boolean) => void;
  data: any;
};

const KhanManualTransModal: React.FC<Props> = ({ data, open = false, toggle }) => {
  const [form] = Form.useForm();

  const [loadingSave, setLoadingSave] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();


  const onSave = async (values: any) => {
    setLoadingSave(true);
    const payload = {
      userID: values.userID,
      depAccID: values.depAccID,
      amount: data.amount.amount,
      type:'manual-khanStatement'
    }
    const res = await manualTransaction(payload)
    console.log(res)
    if(res.status){
      messageApi.open({
        type: 'success',
        content: "Амжилттай хадгаллаа",
      });
      toggle(false)
    }else{
      messageApi.open({
        type: 'error',
        content: res.msg,
      });
    }

    setLoadingSave(false);

  };
  const onCancel = () => {
    setLoadingSave(false);
    toggle(false);
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title=""
        onCancel={onCancel}
        className="info-modal"
        centered
        footer={[]}
      >
        <div className="dialog-body">
          <div className="px-8">
            <div className="font-medium text-lg text-center my-5">
              Manual melbet deposit
            </div>
            <Form
              form={form}
              onFinish={onSave}
              scrollToFirstError
            >
              <div className="font-medium">User ID</div>
              <Form.Item
                rules={[{
                  required: true,
                
             
                }]}
                name="userID">
                <Input
                  style={{ width: '70%' }}
                />
              </Form.Item>

              <div className="font-medium">Depo Acc ID</div>
              <Form.Item
                rules={[{
                  required: true,
              
                }]}
                name="depAccID">
                <Input
                  style={{ width: '70%' }}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loadingSave}>
                  Хадгалах
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>

    </>
  );
};

export default KhanManualTransModal;
