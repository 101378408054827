

import React, {  useState } from 'react';
import { Button,  Modal, message, Form, InputNumber } from 'antd';
import * as _ from 'lodash';

import { DepositAccConfig } from '../../types';
import { manualTransaction } from '../../helpers/firebase_functions';

type Props = {
  open?: boolean;
  toggle: (value: boolean) => void;
  data: DepositAccConfig;
};

const ManualTransactionModal: React.FC<Props> = ({ data, open = false, toggle }) => {
  const [form] = Form.useForm();

  const [loadingSave, setLoadingSave] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();


  const onSave = async (values: any) => {
    setLoadingSave(true);
    const payload = {
      userID: data.userID,
      depAccID: data.uid,
      amount: values.amount,
      type:'manual'
    }
    const res = await manualTransaction(payload)
    console.log(res)
    if(res.status){
      messageApi.open({
        type: 'success',
        content: "Амжилттай хадгаллаа",
      });
      toggle(false)
    }else{
      messageApi.open({
        type: 'error',
        content: res.msg,
      });
    }

    setLoadingSave(false);

  };
  const onCancel = () => {
    setLoadingSave(false);
    toggle(false);
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title=""
        onCancel={onCancel}
        className="info-modal"
        centered
        footer={[]}
      >
        <div className="dialog-body">
          <div className="px-8">
            <div className="font-medium text-lg text-center my-5">
              Manual melbet deposit
            </div>
            <Form
              form={form}
              onFinish={onSave}
              scrollToFirstError
            >
              <div className="font-medium">Гүйлгээний дүн</div>
              <Form.Item
                rules={[{
                  required: true,
                  type: 'number',
                  min: 100,
                  max: 2000000,
                  message: 'Гүйлгээний лимит оруулна уу! min 100 max 2,000,000 '
                }]}
                name="amount">
                <InputNumber
                  style={{ width: '70%' }}
                />
              </Form.Item>


              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loadingSave}>
                  Хадгалах
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>

    </>
  );
};

export default ManualTransactionModal;
