import {Timestamp} from 'firebase/firestore';
import moment from 'moment/moment';
import * as _ from 'lodash';
import {RcFile} from 'antd/es/upload';

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    return 'You can only upload JPG/PNG file!';
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    return "'Image must smaller than 2MB!'";
  }
  return null;
};

export const isValidUrl = (urlString: string) => {
  let url;
  try {
    url = new URL(urlString);
  } catch (e) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const formatCurrency = (value?: number, suffix = '₮') => {
  return !value
    ? ''
    : `${new Intl.NumberFormat('ja-JP').format(value)}${suffix}`;
};

export const getEnumKeys = <O extends object, K extends keyof O = keyof O>(
  obj: O,
): K[] => {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
};

export const convertDateTimeFromTimestamp = (timestamp?: Timestamp) => {
  if (timestamp == null) return '-';
  const val = _.get(timestamp, 'seconds');
  if (val === null) return timestamp.toString();
  const date = new Date(val * 1000);
  return moment(date).format('YYYY.MM.DD HH:mm:s');
};

export const convertDateFromTimestamp = (timestamp: Timestamp) => {
  const val = _.get(timestamp, 'seconds');
  if (val === null) return timestamp.toString();
  const date = new Date(val * 1000);
  return moment(date).format('YYYY.MM.DD');
};

export const convertDateContract = (timestamp?: any) => {
  if (_.isEmpty(timestamp)) return '-';
  const val = _.get(timestamp, 'seconds');
  if (val === null) return timestamp.toString();
  const date = new Date(val * 1000);
  return moment(date).format('YYYY оны MM сарын DD-ний өдөр');
};
