import { useState, useEffect } from 'react';
import * as _ from 'lodash';
import DataTable from '../../components/table/Index';
import type { ColumnsType } from 'antd/es/table';
import { DepositModel } from '../../types';
import { Button, Form, InputNumber, Input, message, Select } from 'antd';
import { Timestamp } from '@firebase/firestore';
import { convertDateTimeFromTimestamp } from '../../helpers/utils';
import { useNavigate } from 'react-router-dom';
import { firestore } from '../../firebase';
import { Routes } from '../../routes/route_helper';

const betTypes = [
  {
    "label": "1xbet",
    "value": "1xbet"
  },
  {
    "label": "Melbet",
    "value": "melbet"
  },


]
const Users = () => {
  const [form] = Form.useForm();
  const [uform] = Form.useForm();
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingUSearch, setLoadingUSearch] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useState('');
  const [data, setData] = useState([]);
  const [searchUsers, setSearchUsers] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selected, setSelected] = useState({} as DepositModel);
  const [messageApi, contextHolder] = message.useMessage();

  const getDepositsData = async () => {
    setLoading(true);
    setUser('')
    let ref: any = firestore.collection('wazs').orderBy('createdAt', 'desc');
    // if (user)
    //   ref = ref.where(FieldPath.documentId(), '==', user,)
    const usersDoc = await ref.get();
    // Execute the query
    const users = usersDoc.docs.map((d: any) => ({ ...d.data(), uid: d.id }))
    setData(users);
    setFilterData(users)

    setLoading(false);
  };

  useEffect(() => {
    getDepositsData();
  }, []);
  useEffect(() => {
    if (user) {
      setFilterData(data.filter((d: any) => {
        return d.userP.includes(user)
      })
      )
    }
  }, [user]);

  const columns: ColumnsType<DepositModel> = [
    {
      key: 'userP',
      title: 'phone',
      dataIndex: 'userP',

    },
    {
      key: 'uid',
      title: 'uid',
      dataIndex: 'uid',
    },

    {
      key: 'block',
      title: 'block',
      dataIndex: 'block',
    },

    {
      key: 'createdAt',
      title: 'Үүсгэсэн огноо',
      dataIndex: 'createdAt',
      render: (createdAt: Timestamp) => convertDateTimeFromTimestamp(createdAt),
    },

  ];



  const onClickRow = (d: any) => {
    console.log(d)
    navigate(Routes.compilePath(Routes.user, { id: d.uid }));

  };

  const onSave = async (values: any) => {
    setLoadingSave(true);
    console.log(values.melbetID)


    const sUsers: any = await firestore.collection('wazsConfig').where("accountID", "==", values.melbetID + '').where('type', '==', values.type).get();
    // Execute the query
    console.log(sUsers)
    if (sUsers.docs.length == 0)
      messageApi.error('Ийм id тай хэрэглэгч олдсонгүй')
    else {
      sUsers.docs.map((d: any) => {
        console.log(d)
        const foundUser = d.data()
        window.open(`/depoAcc/${foundUser.userID}/${d.id}/${values.melbetID}`, "_blank", "noreferrer");
        // navigate(Routes.compilePath(Routes.user, { id: foundUser.userId }));
      });

    }


    setLoadingSave(false);

  };

  const onPhoneUserSearch = async (values: any) => {
    setLoadingUSearch(true);
    console.log(values.melbetID)


    const sUsers: any = await firestore.collection('wazs').where("userP", "==", values.phone + '').get();
    // Execute the query
    console.log(sUsers)
    if (sUsers.docs.length == 0)
      messageApi.error('Ийм утастай тай хэрэглэгч олдсонгүй')
    else {
      sUsers.docs.map((d: any) => {
        console.log(d)
        const foundUser = d.data()
        window.open(`/user/${d.id}`, "_blank", "noreferrer");
        // navigate(Routes.compilePath(Routes.user, { id: foundUser.userId }));
      });

    }


    setLoadingUSearch(false);

  };
  return (
    <div>
      {contextHolder}
      <Form
        form={form}
        onFinish={onSave}
        scrollToFirstError
      >
        <Form.Item
          name="type"
          rules={[{
            required: true,
            message: 'Энд дарж 1xbet Melbet ээ сонгоорой'
          }]}>
          <Select options={betTypes}
            placeholder='Энд дарж 1xbet Melbet сонгох'

          />
        </Form.Item>
        <div className="font-medium">Account ID</div>
        <Form.Item
          rules={[{
            required: true,
            type: 'number',

          }]}
          name="melbetID">
          <InputNumber
            style={{ width: '70%' }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loadingSave}>
            Хэрэглэгч рүү очих
          </Button>
        </Form.Item>
      </Form>
      <Form
        form={uform}
        onFinish={onPhoneUserSearch}
        scrollToFirstError
      >
        <div className="font-medium">Утасны дугаар </div>
        <Form.Item
          rules={[{
            required: true,
            type: 'number',

          }]}
          name="phone">
          <InputNumber
            style={{ width: '70%' }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loadingUSearch}>
            Хэрэглэгч рүү очих
          </Button>
        </Form.Item>
      </Form>
      <DataTable
        tableName="Хэрэглэгч"
        columns={columns}
        data={filterData || []}
        loading={loading}
        headerWidgers={[
        ]}
        reloadData={getDepositsData}
        onSearch={getDepositsData}
        onClickRow={onClickRow}
        filter={[
          <Input
            key="melbetid"
            size="small"
            className="w-auto"
            placeholder="Утасны дугаар"
            value={user}
            onChange={(e) => setUser(e.target.value)}
          />,

        ]}
      />
    </div>
  );
};

export default Users;
