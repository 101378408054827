import {Timestamp} from 'firebase/firestore';

export enum AuthRoles {
  SUPER_ADMIN = 'super_admin',
  ADMIN = 'admin',
  SUPPORT = 'support',
  ANONYMOUS = 'anonymous',
}

export const AUDIT_ADMIN = 'admin';

export type RouteModel = {
  path: string;
  component: () => JSX.Element;
};

export enum LoanStatus {
  loanCreated = 'loanCreated',
  sentRequest = 'sentRequest',
  rejected = 'rejected',
  accepted = 'accepted',
}

export enum RequestStatus {
  created = 'created',
  sent = 'sent',
  verified = 'verified',
  rejected = 'rejected',
}

export enum PaymentStatus {
  SUCCESS = 'SUCCESS',
  DECLINED = 'DECLINED',
}

export type UserModel = {
  uid: string;
  email: string;
  phone: string;
  userType: string;
  active?: boolean;
  registerNumber: string;
  companyName: string;
  firstname: string;
  lastname: string;
  gender: string;
  thumbnail: string;
  city: string;
  cityCode: string;
  district: string;
  street: string;
  ntDugaar: string;
  createdAt: Timestamp;
};

export type OtpModel = {
  type: string;
  phone: string;
  otpCode: number;
  isVerified: boolean;
  createdAt: Timestamp;
};

export type DepositAccConfig = {
  uid:string;
  limit: number;
  lvl:number;
  max_depo_per_day: number;
  userID:string;
};
export type WithdrawtModel={
  uid: string;
  status:string;
  statusMsg:string;
  userID:string;
  amount:string;
  accountID:string;
  type:string;
  depAcc:string;
  khanRes:string;
  updatedAt: Timestamp | undefined;
  createdAt: Timestamp;
}


export type DepositModel = {
  uid: string;
  bonus:number;
  amount: {
    amount:number,
    currency:string
  };
  accountId: string;
  betID: string;
  melbetID: string;
  desc: string;
  status: string;
  statusMsg: string;
  type: string;
  updatedAt: Timestamp | undefined;
  createdAt: Timestamp;
  depAcc:string;
  melbetRes:{
    lvl:string;
    mgs:string;
    status:number;
  }
  userID:string;
  transactionDate:string;
  transactionRemarks:string;
  txnTime:string;

};
export type KhanStatementModel = {
  amountType: {
    cmCode:string,
    codeDescription:string,
    codeType:string
  };
  amount: {
    amount:number,
    currency:string
  };
  endBalance: {
    amount:number,
    currency:string
  };
  beginBalance: {
    amount:number,
    currency:string
  };
  accountId: string;
  transactionDate:string;
  transactionRemarks:string;
  txnTime:string;
  txnBranchId:string;

};


export type AdminModel = {
  email: string;
  createdAt: Timestamp;
};

export type TableSetting = {
  column: any;
  show: boolean;
};
