import { message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import DataTable from '../../components/table/Index';
import { DepositModel } from '../../types';

import { getMobHistory } from '../../helpers/firebase_functions';

const Histories = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState([]);

  const getDepositsData = async () => {
    setLoading(true);

    const khanRes = await getMobHistory('melbet')
    console.log(khanRes)
    let statementData=[] 
    if(khanRes.status && khanRes.data.status){
      setData(khanRes.data.data.Operations.reverse())
    }

 
    // setData(statementData);


    setLoading(false);
  };

  useEffect(() => {
    getDepositsData();
  }, []);

  function numberWithCommas(x: string, curr: string) {
    if(!x)
      return
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + curr;
  }
  const columns: ColumnsType<DepositModel> = [
    {
      key: 'DateUnix',
      title: 'DateUnix',
      dataIndex: 'DateUnix',
      render: (DateUnix: number) =>new Date(DateUnix*1000).toLocaleString(),
    },
    {
      key: 'NameOperation',
      title: 'NameOperation',
      dataIndex: 'NameOperation',
    },
    {
      key: 'Prihod',
      title: 'Deposit',
      dataIndex: 'Prihod',
      render: (amount: any) => numberWithCommas(amount, '₮'),
     
    },
    {
      key: 'Rashod',
      title: 'Withdraw',
      dataIndex: 'Rashod',
      render: (amount: any) => numberWithCommas(amount, '₮'),
    },
   
  ];

  return (
    <div>
      {contextHolder}

      <DataTable
        tableName="History"
        columns={columns}
        data={data || []}
        loading={loading}
        reloadData={getDepositsData}
 
     
      />

    </div>
  );
};

export default Histories;
