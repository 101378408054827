import DataTable from '../../components/table/Index';
import {useCollection} from '../../helpers/firestore';
import type {ColumnsType} from 'antd/es/table';
import {OtpModel} from '../../types';
import {verifiedTag} from '../../helpers/theme_helper';
import {Timestamp} from '@firebase/firestore';
import {convertDateTimeFromTimestamp} from '../../helpers/utils';
import {Input} from 'antd';

const Otp = () => {
  const [otp, otpLoading] = useCollection('appOTP', 'createdAt',undefined,10);

  const columns: ColumnsType<OtpModel> = [
    {
      key: 'phone',
      title: 'Утас',
      dataIndex: 'phone',
    },
    {
      key: 'otpCode',
      title: 'OTP код',
      dataIndex: 'otpCode',
    },
    {
      key: 'isVerified',
      title: 'Баталгаажсан',
      dataIndex: 'isVerified',
      render: (isVerified: boolean) => verifiedTag(isVerified),
    },
    {
      key: 'createdAt',
      title: 'Үүсгэсэн огноо',
      dataIndex: 'createdAt',
      render: (createdAt: Timestamp) => convertDateTimeFromTimestamp(createdAt),
    },
  ];

  return (
    <DataTable
      tableName="OTP код"
      columns={columns}
      data={otp}
      loading={otpLoading}
      filter={[
        <Input
          key="phone"
          size="small"
          className="w-auto"
          placeholder="Утасны дугаар"
        />,
      ]}
    />
  );
};

export default Otp;
