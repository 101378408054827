import {ReloadOutlined, SettingOutlined} from '@ant-design/icons';
import {Button} from 'antd';

type Props = {
  tableName: string;
  reloadData?: () => void;
  headerWidgers?: JSX.Element[];
};

const TableHeader: React.FC<Props> = ({
  reloadData,
  headerWidgers,
  tableName,
}) => {
  return (
    <div className="bg-lightGray p-4 flex items-center gap-3">
      <div className="flex-1">
        <div className="font-medium text-lg">{tableName}</div>
      </div>

      {headerWidgers}
      {reloadData !== undefined && (
        <Button
          className="text-sm"
          shape="circle"
          onClick={reloadData}
          icon={<ReloadOutlined />}
        />
      )}
    </div>
  );
};

export default TableHeader;
