import { useState, useEffect } from 'react';
import * as _ from 'lodash';
import DataTable from '../../components/table/Index';
import type { ColumnsType } from 'antd/es/table';
import { WithdrawtModel } from '../../types';
import { Timestamp } from '@firebase/firestore';
import { convertDateTimeFromTimestamp } from '../../helpers/utils';
import DepositDetailModal from './Detail';
import { Button, Spin, Col, Row, message, Tag, Input, DatePicker, } from 'antd';

import dayjs from 'dayjs';
import type { DatePickerProps } from 'antd';
import { useDoc, useCollection } from '../../helpers/firestore';
import { firestore } from '../../firebase';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import TableHeader from '../../components/table/TableHeader';


import WithdrawModal from './WithdrawModal';

dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';
const Withdraws = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [user, setUser] = useState('');
  const [date, setDate] = useState(dayjs(new Date()));
  const [selDate, setSelDate] = useState(dayjs(new Date()).format(dateFormat));
  const [data, setData] = useState([]);
  const [teamCashBalance, teamCashBalanceLoading] = useDoc('configs/teamCashBalance');
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingBal, setLoadingBal] = useState<boolean>(false);
  const [selected, setSelected] = useState({} as WithdrawtModel);
  const [open, setOpen] = useState(false);

  const [openW, setOpenW] = useState(false);
  const toggleWModal = () => setOpenW(!openW);

  const getDepositsData = async () => {
    setLoading(true);

    const startOfDay = dayjs(selDate).startOf('day').toDate(); // Start of today
    const endOfDay = dayjs(selDate).endOf('day').toDate(); // End of today
    const startOfDayTimestamp = Timestamp.fromDate(startOfDay);
    const endOfDayTimestamp = Timestamp.fromDate(endOfDay);

    let ref: any = firestore.collection('withdraws');
    if (user)
      ref = ref.where('melbetID', '==', user)
    const depoData = await ref.where("createdAt", ">=", startOfDayTimestamp).
      where("createdAt", "<=", endOfDayTimestamp).orderBy('createdAt', 'desc').get();
    // Execute the query
    setData(depoData.docs.map((d: any) => ({ ...d.data(), uid: d.id })));

    setLoading(false);
  };

  useEffect(() => {
    getDepositsData();
  }, []);


  const toggleModal = () => setOpen(!open);
  const onChange: DatePickerProps['onChange'] = (dateJs, dateString) => {
    setSelDate(dateString as string)
  };
  function numberWithCommas(x: string) {
    if(!x)
      return
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ₮" ;
  }
  const columns: ColumnsType<WithdrawtModel> = [
    {
      key: 'uid',
      title: 'uid',
      dataIndex: 'uid',
      width: '5%',
    },
    {
      key: 'type',
      title: 'type',
      dataIndex: 'type',
    },
    {
      key: 'accountID',
      title: 'accountID',
      dataIndex: 'accountID',
    },
   
    {
      key: 'amount',
      title: 'Цэнэглэлт дүн',
      dataIndex: 'amount',
      render: (amount: any) => {return numberWithCommas(amount)}
    },
    {
      key: 'bonus',
      title: 'Bonus',
      dataIndex: 'bonus',
      render: (bonus: any) => {return numberWithCommas(bonus)}
    },
    {
      key: 'statusMsg',
      title: 'Төлөв',
      dataIndex: 'statusMsg',
      render: (statusMsg: string,record) => {
        if (record.status == 'Transferring') {
          return <Tag color="processing"> {statusMsg}</Tag>
        }
        if (record.status == 'Withdrew') {
          return <Tag color="processing"> {statusMsg}</Tag>
        }

        if (record.status == 'Transferred') {
          return <Tag color="success"> {statusMsg}</Tag>
        }
        else  {
          return <Tag color="error"> {statusMsg}</Tag>
        }
  
      }
    },
    {
      key: 'createdAt',
      title: 'Bet Огноо',
      dataIndex: 'createdAt',
      width: '20%',
      render: (createdAt: any) => { return convertDateTimeFromTimestamp(createdAt) }
    },
    
    {
      key: 'updatedAt',
      title: 'Банк гүйлгээ Огноо',
      dataIndex: 'updatedAt',
      width: '20%',
      render: (updatedAt: any) => { return convertDateTimeFromTimestamp(updatedAt) }
    },


  ];



  const onClickRow = (d: any) => {
    console.log(d)
    setSelected(d);
    toggleModal();
  };
  const reloadData = async () => {
    toggleWModal()
  };
  return (
    <div>

      {contextHolder}
      <WithdrawModal  open={openW} toggle={toggleWModal} />

      <Row gutter={[16, 16]}>
        <Col xs={22} lg={12}>
          <TableHeader
            tableName='Melbet данс'
            reloadData={reloadData}
          />
          <div className="card-container px-5 py-7">

            <Spin spinning={teamCashBalanceLoading}>
              {teamCashBalance && (
                <>

                  <div className="flex justify-between items-center mb-2">

                  </div>
                  <div className="mt-5 text-lg text-gray">Close</div>
                  <h2>{teamCashBalance.Close ? ('Хаагдсан') : ("Нээлттэй")}</h2>

                  <div className="mt-5 text-lg text-gray">Үлдэгдэл</div>
                  <h2>{numberWithCommas(teamCashBalance.ImperiumBalance)}</h2>
                  <div className="mt-5 text-lg text-gray">Oгноо</div>
                  <h2>{convertDateTimeFromTimestamp(teamCashBalance.date)}</h2>
                </>
              )}

            </Spin>
          </div>
        </Col>
      </Row>
      <DataTable
        tableName="Withdraws"
        columns={columns}
        data={data || []}
        loading={loading}
        reloadData={getDepositsData}
        onSearch={getDepositsData}
        onClickRow={onClickRow}
        filter={[
          <Input
            key="melbetid"
            size="small"
            className="w-auto"
            placeholder="MelbetID"
            value={user}
            onChange={(e) => setUser(e.target.value)}
          />,
          <DatePicker defaultValue={date} onChange={onChange} />,
        ]}
      />
      <DepositDetailModal data={selected} open={open} toggle={toggleModal} goUser/>
    </div>
  );
};

export default Withdraws;
