import {functions} from '../firebase';
import * as _ from 'lodash';


export const makeKhanWithdraw = async (withdrawID:string) => {
  try {
    const data = await functions.httpsCallable('makeKhanWithdraw')({"withdrawID":withdrawID});
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};




export const manageDeposit = async (depositId: string,type:number) => {
  console.log(depositId,type)
  try {
    const data = await functions.httpsCallable('manageDeposit')({
      depositId:depositId,
      type:type
    });
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};
export const getMobHistory = async (type:string) => {
  try {
    const data = await functions.httpsCallable('getMobHistory')({type:type});
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};

export const checkMobBalance = async (type:string) => {
  try {
    const data = await functions.httpsCallable('checkMobBalance')({type:type});
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};


export const getKhanStatement = async (date:string) => {
  try {
    const data = await functions.httpsCallable('getKhanStatement')({date:date});
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};

export const manualTransaction= async (trans:any) => {
  try {
    const data = await functions.httpsCallable('manualTransaction')(trans);
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};

export const mobWithDraw= async (payload:any) => {
  try {
    const data = await functions.httpsCallable('mobWithDraw')(payload);
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};