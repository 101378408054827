import { useState,useContext } from 'react';

import * as _ from 'lodash';
import DataTable from '../../components/table/Index';
import type { ColumnsType } from 'antd/es/table';
import { DepositModel, WithdrawtModel } from '../../types';

import { convertDateTimeFromTimestamp } from '../../helpers/utils';
import DepositConfigEdit from './DepositConfigEdit';
import { Button, Spin, Col, Row, message, Tag,Tabs } from 'antd';
import dayjs from 'dayjs';
import { useDoc, useCollectionWithMulti } from '../../helpers/firestore';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {  useParams } from 'react-router-dom';
import ManualTransactionModal from './ManualTransactionModal';
import WithdrawDetailModal from '../withdraw/Detail';
import DepositDetailModal from '../depo/Detail';
import WithdrawModal from './WithdrawModal';

import { UserContext} from '../../context/app.contexts';
const { TabPane } = Tabs;
dayjs.extend(customParseFormat);

const DepositAcc = () => {
  const { userRole} = useContext(UserContext);
  console.log("DepositAcc",userRole)

  const { userID, depoID ,accountID} = useParams()
  const [config, configLoading] = useDoc(`wazsConfig/${depoID}`);
  const [user, userLoading] = useDoc(`wazs/${userID}`);
  const [depoAcc, depoAccLoading] = useDoc(`wazs/${userID}/depositAccounts/${depoID}`);
  const [messageApi, contextHolder] = message.useMessage();
  const [selected, setSelected] = useState({} as DepositModel);
  const [wselected, setwSelected] = useState({} as WithdrawtModel);

  const [open, setOpen] = useState(false);
  const [openConfig, setOpenConfig] = useState(false);
  const [openD, setOpenD] = useState(false);
  const [openWD, setOpenWD] = useState(false);

  const [deposits, depositsLoading] = useCollectionWithMulti(`deposits`, 'createdAt', { field: 'depAcc', op: '==', value: depoID },undefined,10);
  const [withdraws, withdrawsLoading] = useCollectionWithMulti(`withdraws`, 'createdAt',{ field: 'depAcc', op: '==', value: depoID },undefined,10);


  const toggleDepoDetailModal = () => setOpen(!open);
  const toggleConfigModal = () => setOpenConfig(!openConfig);

  const toggleWDetailModal = () => setOpenWD(!openWD);
  const toggleDModal = () => setOpenD(!openD);
  const [openW, setOpenW] = useState(false);
  const toggleWModal = () => setOpenW(!openW);
  function numberWithCommas(x: string) {
    if (!x)
      return
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ₮";
  }
  const columns: ColumnsType<DepositModel> = [
    {
      key: 'amount',
      title: 'Цэнэглэлт дүн',
      dataIndex: 'amount',
      render: (amount: any) => { return numberWithCommas(amount.amount) }
    },
    {
      key: 'bonus',
      title: 'Bonus',
      dataIndex: 'bonus',
      render: (bonus: any) => {return numberWithCommas(bonus)}
    },
    {
      key: 'statusMsg',
      title: 'Төлөв',
      dataIndex: 'statusMsg',
      render: (statusMsg: string, record) => {
        if (record.status == 'new') {
          return <Tag color="processing"> {statusMsg}</Tag>
        }
        if (record.status == 'review') {
          return <Tag color="warning"> {statusMsg}</Tag>
        }
        if (record.status == 'Done') {
          return <Tag color="success"> {statusMsg}</Tag>
        }
        if (record.status == 'MelError') {
          return <Tag color="error"> {statusMsg}</Tag>
        }



      }
    },

    {
      key: 'transactionDate',
      title: 'Гүйлгээ огноо',
      dataIndex: 'transactionDate',
      width: 125,
      render: (transactionDate: string) => transactionDate.slice(0, 10),
    },
    {
      key: 'txnTime',
      title: 'цаг',
      dataIndex: 'txnTime',
    },


    {
      key: 'lvl',
      title: 'LVL',
      dataIndex: 'lvl',
      render: (lvl: Number) => { return (<>{`+ ${lvl}`}</>) }
    },
  ];

  const withdrawColumns: ColumnsType<DepositModel> = [
    
    {
      key: 'uid',
      title: 'uid',
      dataIndex: 'uid',
      width: '5%',
    },
    {
      key: 'amount',
      title: 'Таталтын дүн',
      dataIndex: 'amount',
      width: '20%',
      render: (amount: any) => { return numberWithCommas(amount) }
    },
   
    {
      key: 'statusMsg',
      title: 'Төлөв',
      dataIndex: 'statusMsg',
      width: '40%',
      render: (statusMsg: string, record) => {
        if (record.status == 'Transferring') {
          return <Tag color="processing"> {statusMsg}</Tag>
        }
        if (record.status == 'Withdrew') {
          return <Tag color="processing"> {statusMsg}</Tag>
        }
        if (record.status == 'Transferred') {
          return <Tag color="success"> {statusMsg}</Tag>
        }
        else  {
          return <Tag color="error"> {statusMsg}</Tag>
        }
  

      }
    },
    {
      key: 'createdAt',
      title: 'Melbet Огноо',
      dataIndex: 'createdAt',
      width: '20%',
      render: (createdAt: any) => { return convertDateTimeFromTimestamp(createdAt) }
    },
    
    {
      key: 'updatedAt',
      title: 'Банк гүйлгээ Огноо',
      dataIndex: 'updatedAt',
      width: '20%',
      render: (updatedAt: any) => { return convertDateTimeFromTimestamp(updatedAt) }
    },

  
  ];

  const onClickDepo = () => {
    toggleDModal()
  };
  const onClickWith = () => {
    toggleWModal()
  };

  const onClickTableRow = (data:DepositModel) => {
    setSelected(data)
    console.log("onClickTableRow")
    toggleDepoDetailModal();
  };
  const onClickWithdrawRow = (d: any) => {
    console.log(d)
    setwSelected(d);
    toggleWDetailModal();
  };

  

  return (
    <div>
      {contextHolder}
      <Row gutter={[16, 16]}>
        {config && (

          <Col xs={18} lg={8}>
            <div className="card-container px-5 py-7">
              <Spin spinning={configLoading}>
                <div className="flex justify-between items-center mb-2">
                  <div className="text-lg font-medium">
                  </div>
                </div>
                {/* <div className="mt-5 text-lg text-gray">Гүйлгээний лимит</div>

                <div className="mt-1">
                  <h2>{numberWithCommas(config.limit)} </h2>
                </div> */}
                {/* <div className="mt-5 text-lg text-gray">Өдрийн гүйлгээний тоо лимит</div>
                <div className="mt-1" >
                  <h2> {config.max_depo_per_day} </h2>
                </div> */}
                <div className="mt-5 text-lg text-gray">LVL</div>
                <div className="mt-1" >
                  <h2> {config.lvl}</h2>
                </div>
           



              </Spin>
              {/* <Button className="w-full rounded-xl  my-7" type='primary' onClick={toggleConfigModal} >
                Эрх нэмэх
              </Button> */}
            </div>
          </Col>
        )}
        <Col xs={18} lg={8}>
          <div className="card-container px-5 py-7">
            <Spin spinning={userLoading || depoAccLoading}>
              <div className="flex justify-between items-center mb-2">
                <div className="text-lg font-medium">
                </div>
              </div>
         
              <div className="mt-5 text-lg text-gray">Waz Block</div>
              <div className="mt-1" >
                <h2> {user.block} </h2>
              </div>
              <div className="mt-5 text-lg text-gray">Утас</div>

              <div className="mt-1">
                <h2>{(user.userP)} </h2>
                {/* <h2>{numberWithCommas(config.limit)} </h2> */}
              </div>
              <div className="mt-5 text-lg text-gray">Account type</div>
              <div className="mt-1" >
                <h2> {depoAcc.type} </h2>
              </div>
              <div className="mt-5 text-lg text-gray">Account ID</div>
              <div className="mt-1" >
                <h2> {depoAcc.accountID} </h2>
              </div>
              <div className="mt-5 text-lg text-gray">Бүртгүүлсэн огноо</div>
              <div className="mt-1" >
                <h2> {convertDateTimeFromTimestamp(user.createdAt)}</h2>
              </div>


            </Spin>
         
            {userRole =='admin' && (
              <>
               <Button className=" rounded-xl  my-7" type='primary' onClick={onClickDepo} >
              Deposit
            </Button>
            <Button className="rounded-xl  my-7" type='primary' onClick={onClickWith} >
              Withdraw
            </Button>
              </>
            )}
  
          </div>
        </Col>
      </Row>
      <Tabs defaultActiveKey="1">
      <TabPane tab="Deposits" key="1">
      <DataTable
        tableName="Deposits"
        columns={columns}
        data={deposits || []}
        loading={depositsLoading}
        onClickRow={onClickTableRow}
      />
      </TabPane>
      <TabPane tab="Withdraws" key="2">
      <DataTable
        tableName="Withdraws"
        columns={withdrawColumns}
        data={withdraws || []}
        loading={withdrawsLoading}
        onClickRow={onClickWithdrawRow}

      />
       
      </TabPane>

    </Tabs>

    <DepositDetailModal data={selected} open={open} toggle={toggleDepoDetailModal} goUser={false}/>
      <DepositConfigEdit data={config} open={openConfig} toggle={toggleConfigModal} />
      {userRole =='admin' && (
              <>
      <ManualTransactionModal data={config} open={openD} toggle={toggleDModal} />

      <WithdrawModal melbetID={depoAcc.accountID} userID={config.userID} depAcc={depoAcc.uid} open={openW} toggle={toggleWModal} />
    </>)}
      <WithdrawDetailModal data={wselected} open={openWD} toggle={toggleWDetailModal} />
    </div>
  );
};

export default DepositAcc;
