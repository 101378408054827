import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, Alert, Select, Form, message } from 'antd';
import * as _ from 'lodash';
import { convertDateTimeFromTimestamp } from '../../helpers/utils';

import { DepositModel } from '../../types';
import { manageDeposit } from '../../helpers/firebase_functions';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../routes/route_helper';
type Props = {
  open?: boolean;
  toggle: (value: boolean) => void;
  data: DepositModel;
  goUser?: boolean
};

const DepositDetailModal: React.FC<Props> = ({ data, open = false, goUser = false, toggle }) => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
console.log(data)

  useEffect(() => {
    console.log("new data")
    setRefresh(!refresh)
  }, [data]);
  const validate = () => {
    if (
      loading ||
      loadingSave
    )
      return false;
    return true;
  };
  const onSave = async () => {
    if (!validate()) return;
    setLoadingSave(true);
    const res = await manageDeposit(data?.uid, 1);
    console.log(res)
    if (res.status) {
      messageApi.open({
        type: 'success',
        content: "Амжилттай",
      });
      toggle(false)
    }
    else {
      messageApi.open({
        type: 'info',
        content: res.msg,
      });
    }
    setLoadingSave(false);

  };
  const redirectToUser = () => {
    navigate(Routes.compilePath(Routes.depositAcc, { userID: data.userID, depoID: data.depAcc,accountID:data.betID }));
  }

  const getFooter = () => {
    let buttons = [
      <Button
        key="submit"
        className="rounded-xl"
        onClick={() => toggle(false)}
      >
        Хаах
      </Button>,
    ]
    if (goUser) {
      buttons.push(<Button

        className="rounded-xl"
        onClick={redirectToUser}
      >
        Хэрэглэгч руу очих
      </Button>)
    }
    if (data.status == 'MelError' || data.status == 'review') {
      buttons.push(
        <Button
          loading={loadingSave}
          className="rounded-xl"
          onClick={onSave}
        >
          Melbet шилжүүлэг хийх
        </Button>
      )
    }
    return buttons

  }
  const onCancel = () => {
    setLoadingSave(false);
    toggle(false);
  };
  return (
    <>
      {contextHolder}


      <Modal
        open={open}
        title=""
        onCancel={onCancel}
        className="info-modal"
        centered
        footer={getFooter()}
      >
        <div className="dialog-body">

          <div className="dialog-content px-8">
            <div className="font-medium text-lg text-center mb-5">
              Дэлгэрэнгүй
            </div>

            <Form
              form={form}
              onFinish={onSave}
              initialValues={data}
            >
              <div className="font-medium">Хаан данс</div>
              <Form.Item
                name="bankAccountIds">
                <Input

                />
              </Form.Item>
              {data.amount && (
                <>
                  <div className="font-medium">Amount</div>
                  <Form.Item
                    name="summa">
                    <Input
                      defaultValue={`${data.amount.amount}`}
                    />
                  </Form.Item>
                </>
              )}

              <div className="font-medium">Хаан гүйлгээ огноо</div>
              <Form.Item
                name="transaction_Date">
                <Input
                  defaultValue={`${data.transactionDate} ${data.txnTime}`}
                />
              </Form.Item>

              <div className="font-medium">Хаан гүйлгээ утга</div>
              <Form.Item
                name="transactionRemarks">
                <Input

                />
              </Form.Item>
              <div className="font-medium">Status</div>
              <Form.Item
                name="status">
                <Input

                />
              </Form.Item>
              <div className="font-medium">Status Msg</div>
              <Form.Item
                name="statusMsg">
                <Input

                />
              </Form.Item>

              <div className="font-medium">System д бүртгэгдсэн огноо</div>
              <Form.Item
                name="created_At">
                <Input
                  defaultValue={convertDateTimeFromTimestamp(data.createdAt)}
                />
              </Form.Item>
       
              {data.melbetRes && (
                <>
                  <div className="font-medium">Melbet response </div>
                  <Form.Item
                    name="melbetRes.lvl">
                    <Input
                      defaultValue={data.melbetRes.lvl}
                    />
                  </Form.Item>
                  <div className="font-medium">status </div>
                  <Form.Item
                    name="melbetRes.status">
                    <Input
                      defaultValue={data.melbetRes.status}
                    />
                  </Form.Item>
                  <div className="font-medium">msg </div>
                  <Form.Item
                    name="melbetRes.status">
                    <Input
                      defaultValue={data.melbetRes.mgs}
                    />
                  </Form.Item>
                </>
              )}



            </Form>
            {!_.isEmpty(error) && <Alert message={error} type="error" />}
          </div>

        </div>
      </Modal>


    </>
  );
};

export default DepositDetailModal;
