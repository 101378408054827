import { useState, useEffect } from 'react';
import * as _ from 'lodash';
import DataTable from '../../components/table/Index';
import type { ColumnsType } from 'antd/es/table';
import { DepositModel, KhanStatementModel } from '../../types';
import { Button, Spin, Col, Row, message, Alert, Input, DatePicker, } from 'antd';

import dayjs from 'dayjs';
import type { DatePickerProps } from 'antd';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {  getKhanStatement } from '../../helpers/firebase_functions';
import KhanManualTransModal from './KhanManualTransModal';

dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';
const KhanStatements = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [user, setUser] = useState('');
  const [date, setDate] = useState(dayjs(new Date()));
  const [selDate, setSelDate] = useState(dayjs(new Date()).format(dateFormat));
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [openD, setOpenD] = useState(false);
  const [selItem, setSelItem ]= useState<DepositModel>();
  const toggleDModal = () => setOpenD(!openD);


  const getDepositsData = async () => {
    setLoading(true);

    const startOfDay = dayjs(selDate).startOf('day').format('YYYY-MM-DD'); // Start of today

   setUser('')
    const khanRes = await getKhanStatement(startOfDay)

    let statementData=[] 
    if(khanRes.status){
      statementData= khanRes.data
    }

    // if(user)
    //   statementData = statementData.filter((d:KhanStatementModel)=>d.transactionRemarks.includes(user))
    // Execute the query
    setData(statementData);
    setFilterData(statementData)

    setLoading(false);
  };

  useEffect(() => {
    getDepositsData();
  }, []);

  useEffect(() => {
    if(user){
      setFilterData(data.filter((d:KhanStatementModel)=>{
       return  d.transactionRemarks.includes(user) || d.accountId.includes(user) || d.txnTime.includes(user)
      })
      )
    }
  }, [user]);

  const onChange: DatePickerProps['onChange'] = (dateJs, dateString) => {
    setSelDate(dateString as string)
  };
  function numberWithCommas(x: string, curr: string) {
    if(!x)
      return
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + curr;
  }
  const columns: ColumnsType<DepositModel> = [
    {
      key: 'accountId',
      title: 'accountId',
      dataIndex: 'accountId',
    },
    {
      key: 'transactionDate',
      title: 'Огноо',
      dataIndex: 'transactionDate',
      render: (transactionDate: string) =>transactionDate.slice(0,10),
    },
    {
      key: 'txnTime',
      title: 'Time',
      dataIndex: 'txnTime',
    },
    {
      key: 'amount',
      title: 'Дүн',
      dataIndex: 'amount',
      render: (amount: any) => numberWithCommas(amount.amount.toString(), amount.currency),
    },
    {
      key: 'transactionRemarks',
      title: 'утга',
      dataIndex: 'transactionRemarks',
    },
    {
      key: 'beginBalance',
      title: 'Begin balance',
      dataIndex: 'beginBalance',
      render: (beginBalance: any) => numberWithCommas(beginBalance.amount.toString(), beginBalance.currency),
    },
    {
      key: 'endBalance',
      title: 'end balance',
      dataIndex: 'endBalance',
      render: (endBalance: any) => numberWithCommas(endBalance.amount.toString(), endBalance.currency),
    },

  ];
  const onClickRow = (d: DepositModel) => {
    console.log(d)
    if(d.transactionRemarks.includes('melbet-')){
      messageApi.error("Ийм гүйлгээг шилжүүлэх боломжгүй")
      return
    }
    setSelItem(d)
    toggleDModal()

  };
  
  return (
    <div>
      {contextHolder}

      <DataTable
        tableName="Deposits"
        columns={columns}
        data={filterData || []}
        loading={loading}
        reloadData={getDepositsData}
        onSearch={getDepositsData}
        onClickRow={onClickRow}
        filter={[
          <Input
            key="transactionRemarks"
            size="small"
            className="w-auto"
            placeholder="Хайх"
            value={user}
            onChange={(e) => setUser(e.target.value)}
          />,
          <DatePicker defaultValue={date} onChange={onChange} />,
        ]}
      />

<KhanManualTransModal data={selItem} open={openD} toggle={toggleDModal} />
    </div>
  );
};

export default KhanStatements;
